@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

html {
  scroll-padding-top: 73px; /* height of sticky header */
}
